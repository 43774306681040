<template>
  <section class="hero hero--high" :style="heroStyle">
    <Container>
      <b-row>
        <b-col sm="12" md="10" lg="8" xl="5">
          <Margins>
            <div class="hero__title">
              <div class="hero__title-line">
                <slot>
                  <h1 v-html="title" />
                </slot>
              </div>
            </div>

            <div
              class="margins__triple hero__carousel-header hero__carousel-header--small"
            >
              <slot name="description">
                <span>
                  {{ description }}
                </span>
              </slot>
            </div>
          </Margins>
        </b-col>
        <b-col sm="2" md="4">
          <img
            src="../../assets/images/stihl_allinclusive_sticker_se.png"
            alt="On Hold Sticker"
          />
        </b-col>
      </b-row>
    </Container>
  </section>
</template>

<script>
import { Container, Margins } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'Hero',
  mixins: [constants],
  props: {
    title: String,
    description: String,
    backgroundImage: String,
  },
  components: {
    Container,
    Margins,
  },
  computed: {
    heroStyle() {
      if (this.backgroundImage) {
        return { 'background-image': `url(${this.backgroundImage})` };
      }

      return {};
    },
  },
};
</script>

<style lang="scss">
.hero {
  background-color: $color-grey;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: $hero-background-image;
  background-size: cover;
  color: $color-white;
  padding: 10vh 0 2vh;
  margin-top: -2rem;

  @include min-width(sm) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.hero--high {
  display: flex;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  min-height: rem(460px);
}

.hero__title-line {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 40px;
    height: 6px;
    background: $color-white;
  }
}

.hero__carousel-header {
  font-size: $h2-size;
  line-height: 1.4;
  font-family: $font-family;
  font-weight: 700;
  max-width: 500px;
}

.hero__carousel-header--small {
  font-size: $h4-size;
}

.hero__splash-wrapper {
  display: flex;
  align-items: flex-end;
}

.hero__splash {
  height: rem(120px);
  width: rem(120px);
  margin-top: 1rem;
  margin-left: 0;

  @include min-width(lg) {
    margin-top: 0;
    margin-left: auto;
    margin-right: 0;
  }

  @include min-width(xl) {
    margin-bottom: rem(60px);
  }
}
</style>
