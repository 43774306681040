export const cookieRevision = 1;

export const setCookie = (cName, cLevel, exDays) => {
  const d = new Date();
  d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cName}={"levels": ${cLevel}, "revision": ${cookieRevision}};${expires};path=/;Secure`;
};

export const getConsentObjectFromCookie = () => {
  if (!document.cookie) {
    return;
  }
  const cookieValue = document.cookie
    ?.split(';')
    ?.find((cookie) => cookie.trim().startsWith('cookie_consent='))
    ?.split('=')[1];
  return cookieValue ? JSON.parse(cookieValue) : null;
};

export const resetCookieConsent = () => {
  document.cookie = 'cookie_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
};
