<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LÅT ETT PROFFS GÖRA JOBBET</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tänk om du hade en helt egen trädgårdsmästare, som alltid såg
                till att din gräsmatta var perfekt klippt. Upptäck fördelarna
                med STIHL All Inclusive, vår service som gör det både enkelt och
                bekvämt att äga en iMOW® robotgräsklippare.
              </p>
              <p>
                Allt är inkluderat i en fast månadskostnad – installation,
                service och vinterförvaring. Välj bland flera modeller och hitta
                den perfekta matchen för dig och din gräsmatta. Därefter räknas
                den fasta månadskostnaden ut och betalningen delas upp på 60
                månader. Det tillkommer inga räntekostnader, startavgifter eller
                restvärde. Det pris du betalar under finansieringsperioden är
                det pris du skulle betalt för robotgräsklippare, installation,
                vinterservice och vinterförvaring.
              </p>
              <p>Finansieringen görs i samarbete med Resurs Bank.</p>
            </Margins>
          </b-col>
          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Separator, Margins } from '@/components';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Margins,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakta din närmaste<a href="https://stihlallinclusive.se/select-store"> STIHL återförsäljare</a> och välj den modell som passar dig bäst.',
        'Återförsäljaren går igenom och installerar din nya iMOW® i din trädgård.*',
        'Du betalar en fast månadskostnad. Service och vinterförvaring ingår i 4 år.',
        'Förnya ditt avtal efter 4 år och få en ny iMOW®. Eller behåll den du har som din.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
