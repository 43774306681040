import snakeCase from 'lodash/snakeCase';
import cloneDeep from 'lodash/cloneDeep';

import { PERSISTED_STORE_KEY, ORDER_STATUSES } from './constants';

export const getPersistedStore = () => {
  // clean localStorage if frontend version is different
  if (
    !localStorage.APP_VERSION ||
    localStorage.APP_VERSION !== window.config.fairown.frontVersion
  ) {
    localStorage.clear();
    localStorage.setItem('APP_VERSION', window.config.fairown.frontVersion);
  }
  return JSON.parse(localStorage.getItem(PERSISTED_STORE_KEY)) || {};
};

export const setHtmlElementLanguage = (locale) => {
  document.documentElement.setAttribute('lang', locale);
};

export const setDocumentTitle = (title, DEFAULT_TITLE) => {
  document.title = title ? `${title} — ${DEFAULT_TITLE}` : DEFAULT_TITLE;
};

export const getUrlParams = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const kebabToSnakeCaseForTranslation = (string) =>
  snakeCase(string).toUpperCase();

export const parseAgreementText = (agreementText) => {
  const arrayOfInputs = agreementText
    .split(/\n/)
    .filter(
      (item, index) =>
        item !== '' && !(index === 0 && item.split(':').length === 1),
    ); // remove empty and header elements

  return arrayOfInputs.map((item, index) => {
    const splittedItem = item.split(':');

    // Last item is the contract text
    if (arrayOfInputs.length - 1 === index) {
      return {
        key: 'Kontovillkor',
        value: splittedItem[0],
      };
    }

    const trimmedValue = splittedItem[1].trim();

    return {
      key: splittedItem[0],
      value: trimmedValue && trimmedValue !== 'NULL' ? trimmedValue : '',
    };
  });
};

export const getDynamicTranslation = (i18n, key, tokens) => {
  const messages = i18n.messages;

  let translation = getMessagesByKey(messages[i18n.locale], key);
  if (!translation) {
    translation = getMessagesByKey(messages[i18n.fallbackLocale], key);
  }
  if (tokens) {
    translation = translateWithTokens(i18n, translation, key, tokens);
  }

  return translation;
};

const getMessagesByKey = (messages, keys) => {
  let translation = messages;
  keys.split('.').forEach((key) => {
    translation = translation?.[key];
  });

  return translation;
};

export const translateWithTokens = (i18n, translation, key, tokens) => {
  let translationWithTokens = translation;
  if (typeof translationWithTokens === 'string') {
    translationWithTokens = i18n.t(`${key}`, tokens);
  } else {
    Object.keys(translationWithTokens).forEach((k) => {
      translationWithTokens[k] = translateWithTokens(
        i18n,
        translationWithTokens[k],
        `${key}.${k}`,
        tokens,
      );
    });
  }
  return translationWithTokens;
};

export const changeProperty = (
  array,
  searchProperty,
  searchValue,
  property,
  newValue,
) => {
  const clonedArray = cloneDeep(array);
  const currentIndex = clonedArray.findIndex(
    (o) => o[searchProperty] === searchValue,
  );

  clonedArray[currentIndex][property] = newValue;

  return clonedArray;
};

export const sortByStringProperty = (property) => (a, b) =>
  a[property] < b[property] ? -1 : Number(a[property] > b[property]);

export const mapStyles = () => [
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '2.00',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7b7b7b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#46bcec',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c8d7d4',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#070707',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
];

export const filterByOptionValue = (array, values) => {
  if (!values) {
    return [];
  }

  return array.filter((option) => values.includes(option.value));
};

export const orderStatusVariant = (status) => {
  switch (status) {
    case ORDER_STATUSES.SUBMITTED:
      return 'info';
    case ORDER_STATUSES.DENIED:
    case ORDER_STATUSES.CANCELLED:
      return 'error';
    case ORDER_STATUSES.COMPLETED:
      return 'default';
    case ORDER_STATUSES.HANDED_OVER:
      return 'success';
    default:
  }
};

export const arrayFromString = (array, separator = ',') => {
  return array?.split(separator);
};

export const mapToOptionValue = (array) => {
  return (array || []).map((option) => option.value);
};
