import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import { setHtmlElementLanguage, getPersistedStore } from './utils';

const persistedStore = getPersistedStore();

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const locale =
  persistedStore.language || window.config.vue.vueAppI18nLocale || 'sv';
const fallbackLocale = window.config.vue.vueAppI18nFallbackLocale || 'en';

setHtmlElementLanguage(locale);

export default new VueI18n({
  locale,
  fallbackLocale,
  messages: loadLocaleMessages(),
});
