import Vue from 'vue';
import 'lazysizes';
import {
  LayoutPlugin,
  ModalPlugin,
  ProgressPlugin,
  CollapsePlugin,
  CarouselPlugin,
  EmbedPlugin,
  TooltipPlugin,
  ListGroupPlugin,
} from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import 'focus-visible';
import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm';
import * as VueGoogleMaps from 'vue2-google-maps';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { dictionary } from './validation';

import './assets/scss/setup.scss';
import './assets/scss/main.scss';

const googleAnalyticsTrackingId =
  window.config.vue.vueAppGoogleAnalyticsTrackingId;

if (googleAnalyticsTrackingId !== '') {
  Vue.use(VueGtag, {
    config: {
      id: googleAnalyticsTrackingId,
    },
  });
}

const googleTagManagerId = window.config.vue.vueAppGoogleTagManagerId;

if (googleTagManagerId !== '') {
  Vue.use(VueGtm, {
    id: googleTagManagerId,
    enabled: false,
    vueRouter: router,
  });
}

Vue.use(ModalPlugin);
Vue.use(LayoutPlugin);
Vue.use(ProgressPlugin);
Vue.use(CollapsePlugin);
Vue.use(CarouselPlugin);
Vue.use(EmbedPlugin);
Vue.use(TooltipPlugin);
Vue.use(ListGroupPlugin);

Vue.use(VeeValidate, {
  i18n,
  dictionary,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_ID,
    libraries: 'places,geometry',
    region: 'SE',
    language: 'sv',
  },
});

Vue.config.productionTip = false;

Vue.prototype.window = window; // This is needed for using window object inside <template>

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
