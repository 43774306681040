export const API_BASE_URL = process.env.VUE_APP_API_URL;
export const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
export const SIGNICAT_LOGIN_FORM_URL = '/login/signicat';
export const MAIN_LOCALE = window.config.vue.vueAppI18nLocale;
export const SELLER_CODE = process.env.VUE_APP_SELLER_CODE;
export const AVAILABLE_LOCALES = (
  window.config.vue.vueAppI18nAvailableLocales || MAIN_LOCALE
).split(',');
export const ENVIRONMENT = window.config.vue.vueAppEnvironment;

export const ROUTES = {
  HOME: {
    path: '/',
    name: 'home',
  },
  MAINTENANCE: {
    path: '/maintenance',
    name: 'maintenance',
  },
  SELECT_BUNDLE: {
    path: '/select-bundle',
    name: 'select-bundle',
  },
  APPLY: {
    path: '/apply',
    name: 'apply',
  },
  SELECT_STORE: {
    path: '/select-store',
    name: 'select-store',
  },
  FAQ: {
    path: '/faq',
    name: 'faq',
  },
  LOAN_APPLICATION: {
    path: '/apply/loan-application',
    name: 'loan-application',
  },
  LOAN_APPLICATION_LOADING: {
    path: '/apply/loan-application/loading',
    name: 'loan-application-loading',
  },
  LOAN_APPLICATION_SUCCESS: {
    path: '/apply/loan-application/success',
    name: 'loan-application-success',
  },
  LOAN_APPLICATION_FAIL: {
    path: '/apply/loan-application/fail/*',
    name: 'loan-application-fail',
  },
  LOAN_APPLICATION_REJECT: {
    path: '/apply/loan-application/reject/*',
    name: 'loan-application-reject',
  },
  DECISION_MANUAL_INSPECTION: {
    path: '/decision/manual-inspection',
    name: 'decision-manual-inspection',
  },
  LOAN_PAYMENT_LOADING: {
    path: '/apply/loan-application/payment',
    name: 'loan-payment-loading',
  },
  DECISION_DENIED: {
    path: '/decision/denied',
    name: 'decision-denied',
  },
  DECISION_SIGN_MANUALLY: {
    path: '/decision/sign/manually',
    name: 'decision-sign-manually',
  },
  BANK_ID_FAIL: {
    path: '/bank-id/fail',
    name: 'bank-id-fail',
  },
  LOGIN_SUCCESS: {
    path: '/login-success',
    name: 'login-success',
  },
  LOGIN_FAIL: {
    path: '/login-fail',
    name: 'login-fail',
  },
  CREDIT_CARD_PAYMENT: {
    path: '/credit-card/payment',
    name: 'credit-card-payment',
  },
  CREDIT_CARD_PAYMENT_FAIL: {
    path: '/credit-card/payment-fail',
    name: 'credit-card-payment-fail',
  },
};

export const SERVICE_TYPES = {
  SERVICE: 'service',
  WINTER_STORAGE: 'winter_storage',
  INSTALLATION: 'installation',
  PERFECT: 'perfect',
};
export const ICON_TYPES = {
  INSTALLATION: 'INSTALLATION',
  FOLLOW_UP: 'FOLLOW_UP',
  WINTER_STORAGE: 'WINTER_STORAGE',
  PERFECT_GRASS: 'PERFECT_GRASS',
};

export const PERSISTED_STORE_KEY = 'fairown-persisted-store';

export const STEPS = {
  select: 0,
  apply: 1,
  paymentSubmitted: 2,
};

export const REGEX = {
  SV: {
    identificationNumber:
      '^(18\\d{2}|19\\d{2}|20\\d{2}|\\d{2})(0[1-9]|1[0-2])([0][1-9]|[1-2][0-9]|3[0-1])(\\-|\\+)?([\\d]{4})$',
    mobile:
      '^(0|\\+46|0046)[ |-]?(200|20|70|73|76|74|[1-9][0-9]{0,2})([ |-]?[0-9]){5,8}$',
    postalCode: '^\\d{3}\\s*\\d{2}$',
    serialNumber: '^\\d{9}$',
  },
};

export const RESURS_DECISION_STATUSES = {
  STARTED: 'STARTED',
  FROZEN: 'FROZEN',
  RESERVED: 'RESERVED',
  DECLINED: 'DECLINED',
};

export const INDIVIDUALIZED_TERMS_TYPES = ['COMMON', 'ISEKKI_NEW_ACCOUNT'];

export const DELIVERY_TYPES = {
  // HOME: 'HOME',
  STORE: 'STORE',
};

export const SIGNATURE_STATUSES = {
  CREATED: 'CREATED',
  NOTIFIED: 'NOTIFIED',
  STARTED: 'STARTED',
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN',
};

export const SIGNING_TYPES = { MANUAL: 'MANUAL', BANKID: 'BANKID' };

export const ORDER_STATUSES = {
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  HANDED_OVER: 'HANDED_OVER',
  DENIED: 'DENIED',
};

export const LOCALES = {
  EN: 'en',
  SV: 'sv',
};

export const STORE = {
  name: 'Stihl',
  lang: 'sv',
  availableLocales: AVAILABLE_LOCALES,
  mapConfig: {
    centerPosition: { lat: 60.5399699, lng: 9.7065296 },
    zoom: 6.3,
  },
};

export const CUSTOMER_TYPES = {
  NATURAL: 'NATURAL',
};

export const COOKIE_CONSENT_LEVELS = {
  NECESSARY: 'NECESSARY',
  ANALYTIC: 'ANALYTIC',
  TARGETING: 'TARGETING',
};

const socialMediaItems = [
  {
    icon: 'BIconFacebook',
    url: 'https://www.facebook.com/stihlsverige',
    label: 'FACEBOOK',
  },
  {
    icon: 'BIconInstagram',
    url: 'https://www.instagram.com/stihlse/',
    label: 'INSTAGRAM',
  },
  {
    icon: 'BIconYoutube',
    url: 'https://www.youtube.com/channel/UCpF0q6QledHsr_zuuB2A1LA',
    label: 'YOUTUBE',
  },
];

const contactItems = [
  { label: 'ADDRESS', value: 'Box 3062, 44303 Stenkullen' },
  { label: 'EMAIL', value: 'info@stihl.se' },
];

const companyItems = [
  { label: 'ABOUT_US', url: 'https://www.stihl.se/om-oss.aspx' },
];

export const footerDataMap = new Map([
  ['SocialMediaItems', socialMediaItems],
  ['ContactItems', contactItems],
  ['CompanyItem', companyItems],
]);

export const bundleHeroImageMap = {
  IMOW_RMI: 'sai_rmi_hero.jpg',
  IMOW: 'sai_imow_hero.jpg',
  IMOW_EVO: 'sai_imow-evo_hero.jpg',
  BASE: 'hero.jpg',
};
