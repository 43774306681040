import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import {
  ASK_QUESTION,
  CREATE_ACCOUNT,
  DUMMY_LOGIN,
  GET_APPLICATION_STATUS,
  GET_BUNDLES,
  GET_INDIVIDUALIZED_TERMS,
  GET_LOGIN_INFO,
  GET_STORES,
  HANDLE_COOKIE_CONSENT,
  HANDLE_PAYMENT_RESPONSE,
  HAS_ERROR,
  HAS_LOAN_APPLICATION_ERROR,
  IS_BUNDLE_ORDER_COMPLETE,
  IS_LOADING,
  NOTIFICATION,
  POLL_PAYMENT_RESPONSE,
  RESET_STORE,
  SET_APPLICATION_STATUS,
  SET_BUNDLE_SELECTED_STORE_CODE,
  SET_BUNDLES,
  SET_COOKIE_CONSENT,
  SET_CURRENT_STEP,
  SET_GEOCODER_ERROR,
  SET_INDIVIDUALIZED_TERMS,
  SET_LANGUAGE,
  SET_LOAN_APPLICATION_DATA,
  SET_LOAN_APPLICATION_DECISION,
  SET_LOAN_REFERENCE_NUMBER,
  SET_LOGIN_INFO,
  SET_SELECTED_BUNDLE,
  SET_SIGNATURE_STATUS,
  SET_SIGNING_DATA,
  SET_STORES,
  SIGN_WITH_BANK_ID,
  SUBMIT_LOAN_APPLICATION,
} from '@/types';
import HttpClient from '@/api/HttpClient';
import {
  COOKIE_CONSENT_LEVELS,
  CUSTOMER_TYPES,
  PERSISTED_STORE_KEY,
  RESURS_DECISION_STATUSES,
  ROUTES,
  STEPS,
} from './constants';
import router from './router';
import i18n from './i18n';
import { getPersistedStore } from './utils';
import SubscriptionApi from '@/api/SubscriptionApi';
import {
  cookieRevision,
  getConsentObjectFromCookie,
  setCookie,
} from '@/utils/CookieUtil';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: PERSISTED_STORE_KEY,
  storage: window.localStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    language: state.language,
    currentStep: state.currentStep,
    selectedBundle: state.selectedBundle,
    loginInfo: state.loginInfo,
    loanApplicationData: state.loanApplicationData,
    loanApplicationDecision: state.loanApplicationDecision,
    loanReferenceNumber: state.loanReferenceNumber,
    signingData: state.signingData,
    storeCode: state.storeCode,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

const persistedStore = getPersistedStore();

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    language: persistedStore.language || i18n.locale,
    currentStep: persistedStore.currentStep || STEPS.select,
    stores: [],
    bundles: [],
    selectedBundle: persistedStore.selectedBundle || null,
    loginInfo: null,
    loanApplicationData: persistedStore.loanApplicationData || null,
    isLoading: false,
    consentInfo: getConsentObjectFromCookie(),
    hasError: false,
    notification: null,
    hasLoanApplicationError: false,
    loanApplicationDecision: null,
    loanReferenceNumber: persistedStore.loanReferenceNumber || null,
    individualizedTerms: [],
    signingData: null,
    signatureStatus: null,
    storeCode: persistedStore.storeCode || null,
    bundleOrders: {
      content: [],
    },
    geocoderError: null,
    retries: 0,
    maxRetries: 15,
  },
  actions: {
    [SET_LANGUAGE]({ commit }, language) {
      commit(SET_LANGUAGE, language);
    },
    [SET_COOKIE_CONSENT]({ commit }, consentLevels) {
      setCookie('cookie_consent', JSON.stringify(consentLevels), 365);
      commit(SET_COOKIE_CONSENT, {
        levels: consentLevels,
        revision: cookieRevision,
      });
    },
    [HANDLE_COOKIE_CONSENT]({ commit, state }) {
      if (state.consentInfo?.revision !== cookieRevision) {
        document.cookie =
          'cookie_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        commit(SET_COOKIE_CONSENT, null);
        return;
      }
      if (
        state.consentInfo?.levels?.includes(COOKIE_CONSENT_LEVELS.TARGETING)
      ) {
        window.fbq('consent', 'grant');
      } else {
        window.fbq('consent', 'revoke');
      }
      if (state.consentInfo?.levels?.includes(COOKIE_CONSENT_LEVELS.ANALYTIC)) {
        this._vm.$gtm?.enable(true);
        this._vm.$ga?.enable();
      } else {
        this._vm.$gtm?.enable(false);
        this._vm.$ga?.disable();
      }
    },
    async [GET_STORES]({ commit }) {
      try {
        const stores = await SubscriptionApi.getStores();

        commit(SET_STORES, stores);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_BUNDLES]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const bundles = await SubscriptionApi.getBundles();

        commit(SET_BUNDLES, bundles);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_SELECTED_BUNDLE]({ commit }, bundle) {
      commit(SET_SELECTED_BUNDLE, bundle);
      commit(SET_CURRENT_STEP, bundle ? STEPS.apply : STEPS.select);
    },
    async [ASK_QUESTION]({ commit, state }, data) {
      try {
        commit(IS_LOADING, true);

        await SubscriptionApi.askQuestion({
          ...data,
          language: state.language,
        });
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [DUMMY_LOGIN]({ commit, dispatch }, formData) {
      try {
        commit(IS_LOADING, true);
        await SubscriptionApi.dummyLogin({ ...formData });

        dispatch(GET_LOGIN_INFO);
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_LOGIN_INFO]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const response = await SubscriptionApi.getAuthInfo();
        commit(SET_LOGIN_INFO, response);

        router.push({ name: ROUTES.LOAN_APPLICATION.name });
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_LOAN_APPLICATION_DATA]({ commit }, data) {
      commit(SET_LOAN_APPLICATION_DATA, data);
    },
    async [SUBMIT_LOAN_APPLICATION]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const response = await SubscriptionApi.submitLoanApplication(
          state.loanApplicationData,
        );

        commit(SET_LOAN_REFERENCE_NUMBER, response.referenceNumber);
        const paymentResponse = await SubscriptionApi.submitPayment(
          response.referenceNumber,
          state.language,
        );

        commit(SET_CURRENT_STEP, STEPS.paymentSubmitted);
        window.location.href = paymentResponse.redirectionUrl;
      } catch (error) {
        commit(HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [POLL_PAYMENT_RESPONSE]({ state, dispatch, commit }) {
      state.retries = 0;
      const interval = setInterval(async () => {
        try {
          const response = await SubscriptionApi.pollPaymentResponse(
            state.loanReferenceNumber,
          );

          if (state.retries >= state.maxRetries) {
            clearInterval(interval);
            commit(HAS_LOAN_APPLICATION_ERROR, true);
            return;
          } else if (response !== RESURS_DECISION_STATUSES.STARTED) {
            dispatch(HANDLE_PAYMENT_RESPONSE, response);
            clearInterval(interval);
          } else {
            state.retries++;
          }
        } catch (error) {
          commit(HAS_LOAN_APPLICATION_ERROR, error);
        }
      }, 5000);
    },
    async [HANDLE_PAYMENT_RESPONSE]({ state, commit }, response) {
      const { FROZEN, RESERVED, DECLINED } = RESURS_DECISION_STATUSES;

      switch (response) {
        case FROZEN:
          commit(IS_LOADING, false);
          await router.push({ name: ROUTES.DECISION_MANUAL_INSPECTION.name });
          break;
        case RESERVED:
          commit(IS_LOADING, false);
          await router.push({ name: ROUTES.LOAN_APPLICATION_SUCCESS.name });
          break;
        case DECLINED:
          commit(IS_LOADING, false);
          await router.push({ name: ROUTES.DECISION_DENIED.name });
          break;
        default:
          commit(IS_LOADING, false);
          await router.push({
            name: ROUTES.HOME.name,
            params: { productCode: state.productCode },
          });
      }
    },

    async [IS_BUNDLE_ORDER_COMPLETE]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const referenceNumber = state.loanApplicationDecision.referenceNumber;

        const data = {
          language: state.language,
        };
        await HttpClient.isBundleOrderComplete(referenceNumber, data);
      } catch (error) {
        router.push({ name: ROUTES.BANK_ID_FAIL.name });
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_APPLICATION_STATUS]({ commit, state }) {
      try {
        const referenceNumber = state.loanApplicationDecision.referenceNumber;
        const applicationStatus = await HttpClient.getApplicationStatus(
          referenceNumber,
        );

        commit(SET_APPLICATION_STATUS, applicationStatus);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [CREATE_ACCOUNT]({ commit, state }) {
      try {
        const referenceNumber = state.loanApplicationDecision.referenceNumber;
        const response = await HttpClient.createAccount(referenceNumber);

        commit(SET_LOAN_APPLICATION_DECISION, {
          ...response,
          ...state.applicationStatus,
        });

        commit(SET_CURRENT_STEP, STEPS.sign);
        router.push({ name: ROUTES.DECISION_SIGN.name });
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    // Back office
    async [SIGN_WITH_BANK_ID]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const data = {
          referenceNumber: state.loanApplicationDecision.referenceNumber,
          successUrl: `${window.location.origin}${ROUTES.LOAN_APPLICATION_SUCCESS.path}`,
          failUrl: `${window.location.origin}${ROUTES.BANK_ID_FAIL.path}`,
        };
        const signingData = await HttpClient.createSigningSession(data);

        window.location.href = signingData.signingUrl; // eslint-disable-line require-atomic-updates
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_INDIVIDUALIZED_TERMS]({ commit, state }, type) {
      try {
        commit(IS_LOADING, true);

        const data = {
          accountNumber: state.loanApplicationDecision.accountNumber,
          amount: state.loanApplicationDecision.approvedAmount,
          bankProductType: state.selectedBundle.bundle.bankProductType,
          termsType: type,
          language: state.language,
          customerType: CUSTOMER_TYPES.NATURAL,
        };
        const termsData = await HttpClient.getIndividualizedTerms(data);

        commit(SET_INDIVIDUALIZED_TERMS, termsData);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
  },
  mutations: {
    [RESET_STORE](state, isHalfSweep) {
      state.currentStep = STEPS.select;
      state.isAdmin = null;
      state.selectedBundle = null;
      state.loginInfo = null;
      state.loanReferenceNumber = null;
      state.loanApplicationData = null;
      state.loanApplicationDecision = isHalfSweep
        ? {
            referenceNumber: state.loanApplicationDecision.referenceNumber,
            accountNumber: state.loanApplicationDecision.accountNumber,
          }
        : null;
      state.storeCode = null;
    },
    [SET_LANGUAGE](state, language) {
      state.language = language;
    },
    [SET_COOKIE_CONSENT](state, consentInfo) {
      state.consentInfo = consentInfo;
    },
    [IS_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [HAS_ERROR](state, error) {
      state.hasError = error;
    },
    [NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [SET_CURRENT_STEP](state, step) {
      state.currentStep = step;
    },
    [SET_STORES](state, stores) {
      state.stores = stores;
    },
    [SET_BUNDLES](state, bundles) {
      state.bundles = bundles;
    },
    [SET_SELECTED_BUNDLE](state, bundle) {
      state.selectedBundle = bundle;
    },
    [SET_LOGIN_INFO](state, data) {
      state.loginInfo = data;
    },
    [SET_LOAN_APPLICATION_DATA](state, data) {
      state.loanApplicationData = data;
    },
    [HAS_LOAN_APPLICATION_ERROR](state, error) {
      state.hasLoanApplicationError = error;
    },
    [SET_LOAN_APPLICATION_DECISION](state, decision) {
      state.loanApplicationDecision = decision;
    },
    [SET_LOAN_REFERENCE_NUMBER](state, referenceNumber) {
      state.loanReferenceNumber = referenceNumber;
    },
    [SET_BUNDLE_SELECTED_STORE_CODE](state, storeCode) {
      state.storeCode = storeCode;
    },
    [SET_SIGNATURE_STATUS](state, status) {
      state.signatureStatus = status;
    },
    [SET_SIGNING_DATA](state, data) {
      state.signingData = data;
    },
    [SET_INDIVIDUALIZED_TERMS](state, termsData) {
      state.individualizedTerms.push(termsData);
    },
    [SET_APPLICATION_STATUS](state, status) {
      state.applicationStatus = status;
    },
    [SET_GEOCODER_ERROR](state, status) {
      state.geocoderError = status;
    },
  },
  getters: {
    hasConsentByLevel: (state) => (consentLevel) =>
      state.consentInfo?.levels?.includes(consentLevel),
  },
});
