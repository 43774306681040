<template>
  <Margins class="bundle">
    <h5 class="text-center">{{ bundle.name }}</h5>
    <dl class="margin-bottom-0">
      <div
        v-for="[key, value] in technicalDetails"
        :key="`${key}-technical-details`"
        class="text-extra-small bundle__dl-item"
      >
        <dt class="text-uppercase text-strong">
          {{ $t(`BUNDLE.TECHNICAL_DETAILS.${key}`) }}
        </dt>
        <dd class="text-uppercase margin-left-0">
          {{ translateValue(key, value) }}
        </dd>
      </div>
    </dl>

    <div class="bundle__bottom">
      <Separator size="small" hasLine />

      <div class="text-center">
        <h4 class="h3">
          {{ bundle.paymentAmount }} kr/{{ $t('MONTH_ABBREVIATION') }}
        </h4>
        <p class="text-extra-small">
          {{ $t('BUNDLE.PRICE_INFO', [bundle.renewalPeriodMonths]) }}
        </p>
      </div>

      <Button
        block
        variant="inverse-primary"
        class="margins__one"
        @click="selectBundle"
      >
        {{ $t('SELECT') }}
      </Button>
    </div>
  </Margins>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_SELECTED_BUNDLE } from '@/types';
import { constants } from '@/mixins';
import { Margins, Button, Separator } from '@/components';

export default {
  name: 'Bundle',
  mixins: [constants],
  components: {
    Margins,
    Button,
    Separator,
  },
  props: {
    bundle: Object,
  },
  methods: {
    ...mapActions([SET_SELECTED_BUNDLE]),
    selectBundle(e) {
      if (e) e.preventDefault();

      this.SET_SELECTED_BUNDLE(this.bundle);
      this.$router.push({ name: this.ROUTES.APPLY.name });
    },
    translateValue(key, value) {
      switch (key) {
        case 'appControl':
          return this.$t(`${value === 'NEJ' ? `NO` : `YES`}`);
        default:
          return value;
      }
    },
  },
  computed: {
    technicalDetails() {
      return Object.entries(
        this.bundle.additionalData.technicalDetails ?? [],
      ).map(([key, value]) => [key, value]);
    },
  },
};
</script>

<style lang="scss">
.bundle {
  display: flex;
  flex-direction: column;
  border: rem(1px) solid $color-grey-dark;
  padding: 1.5rem 1rem 1rem;
  height: 100%;
}

.bundle__dl-item {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 0.5rem;

  & + .bundle__dl-item {
    margin-top: 0.25rem;
  }
}

.bundle__bottom {
  margin-top: auto;
}
</style>
