<template>
  <div class="loan-application-login">
    <Separator size="large" />
    <Margins class="text-center">
      <Button @click="handleLogin" variant="inverse-primary">{{
        $t('LOGIN_BANK_ID')
      }}</Button>
      <div class="text-small text-grey margins__triple">
        <a href="https://www.bankid.com/" target="_blank">{{
          $t('LOGIN_INFO')
        }}</a>
      </div>
      <div v-if="isNotProduction">
        <div class="margins__triple">
          <Button v-b-toggle.fields-collapse variant="text">
            Show fields
          </Button>
        </div>
        <b-collapse id="fields-collapse">
          <Card>
            <div>
              <b-row class="justify-content-center" cols="1">
                <b-col>
                  <Input
                    :label="`${$t('FORM.FIRST_NAME')} *`"
                    name="firstName"
                    autocomplete="first-name"
                    v-model.trim="formData.customer.firstName"
                    :error="errors.first('firstName')"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
                  />
                </b-col>
                <b-col>
                  <Input
                    :label="`${$t('FORM.LAST_NAME')} *`"
                    name="lastName"
                    autocomplete="family-name"
                    v-model.trim="formData.customer.lastName"
                    :error="errors.first('lastName')"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
                  />
                </b-col>
                <b-col>
                  <Input
                    :label="`${$t('FORM.ID_NUMBER')} *`"
                    name="identificationNumber"
                    placeholder="00000000-0000"
                    v-model.trim="formData.customer.personalId"
                    :error="errors.first('identificationNumber')"
                    v-validate="{
                      required: true,
                      regex: REGEX.SV.identificationNumber,
                    }"
                    :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
                  />
                </b-col>
              </b-row>
            </div>
          </Card>
        </b-collapse>
        <div class="margins__triple">
          <Button @click="dummyLogin" variant="inverse-primary">
            Dummy login
          </Button>
        </div>
      </div>
    </Margins>
    <BankIdSVG class="bank-id-svg" />
  </div>
</template>

<script>
import { Margins, Button, Separator, Card, Input } from '@/components';
import { constants } from '@/mixins';
import BankIdSVG from '@/assets/images/bank-id-icon.svg';
import { mapActions } from 'vuex';
import { DUMMY_LOGIN } from '@/types';
import { ENVIRONMENT } from 'fairown-stihl-finland/src/constants';
import { SIGNICAT_LOGIN_FORM_URL } from '@/constants';

export default {
  name: 'LoanApplicationLogin',
  mixins: [constants],
  components: {
    Margins,
    Button,
    Separator,
    BankIdSVG,
    Card,
    Input,
  },
  data() {
    return {
      formData: {
        authenticated: true,
        customer: {
          personalId: '198203086718',
          firstName: 'Helene',
          lastName: 'Grundström',
        },
      },
    };
  },
  methods: {
    ...mapActions([DUMMY_LOGIN]),
    handleLogin() {
      window.location.href = SIGNICAT_LOGIN_FORM_URL;
    },
    dummyLogin() {
      this.DUMMY_LOGIN(this.formData.customer);
    },
  },
  computed: {
    isNotProduction() {
      return ENVIRONMENT !== 'production';
    },
  },
};
</script>

<style lang="scss">
.loan-application-login {
  position: relative;
  z-index: 0;
}

.bank-id-svg {
  position: absolute;
  fill: $color-grey-border;
  width: 55%;
  top: -90%;
  z-index: -1;
  right: -15%;
  transform: rotate(-30deg);
}
</style>
