// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesSV from 'vee-validate/dist/locale/sv';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
    },
  },
  sv: {
    ...validationMessagesSV,
    messages: {
      ...validationMessagesSV.messages,
    },
  },
};
